import { css } from '@emotion/react'
import { FormatDateOptions, createIntl, createIntlCache } from '@formatjs/intl'
import createRenderer from 'basic-parser'
import { useCallback, useMemo } from 'react'

import { InternalLink, ExternalLink } from 'src/components/Link'
import { TextSpan } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { SHORT_MONTH_DATE_FORMAT, SHORT_TIME_FORMAT } from 'src/constants/date'
import { Translation, TranslationKey } from 'src/constants/translation'
import en_US from 'src/translations/en-US'
import { Duration } from 'src/utils/date'

const cache = createIntlCache()
const intl = createIntl(
  {
    defaultLocale: 'en-US',
    locale: 'en-US',
    messages: en_US,
  },
  cache,
)

type DefinedNode = Exclude<React.ReactNode, undefined | null>

const renderElements = createRenderer<DefinedNode>([
  {
    match: '&w',
    renderMatch: ({ key, children }) => (
      <TextSpan
        key={key}
        css={css`
          color: ${COLOR.WHITE};
        `}
      >
        {children}
      </TextSpan>
    ),
  },
  {
    match: '_',
    renderMatch: ({ key, children }) => <em key={key}>{children}</em>,
  },
  {
    match: '\\*',
    renderMatch: ({ key, children }) => <strong key={key}>{children}</strong>,
  },
  {
    match: '~',
    renderMatch: ({ key, children }) => <del key={key}>{children}</del>,
  },
  {
    match: { start: '\\[', end: '\\](?:\\(([^\\)]+?)\\))?' },
    renderMatch: ({ key, children, endMatches }) => {
      const href = endMatches[0]

      if (href?.startsWith('/') === true && href?.startsWith('//') !== true) {
        return (
          <InternalLink key={key} to={href}>
            {children}
          </InternalLink>
        )
      }

      return (
        <ExternalLink key={key} href={href}>
          {children}
        </ExternalLink>
      )
    },
  },
])

type InterpolateInner<
  Key extends string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  Data extends Record<string, string | number> = {},
> =
  // prettier-ignore
  Key extends `${string}{${infer Variable}, plural,${string}}}${infer Rest}`
    ? InterpolateInner<Rest, Data & { [key in Variable]: string | number }> :
  Key extends `${string}{${infer Variable}}${infer Rest}`
    ? InterpolateInner<Rest, Data & { [key in Variable]: string | number }> :
  Data

type Interpolate<Key extends TranslationKey> = InterpolateInner<
  Translation[Key]
>

export interface UseTranslateReturn {
  /**
   * NOTE: Only use this method when translating to a string is required.
   * Prefer using the translate method whenever possible.
   */
  translateToString: <Key extends TranslationKey, Data = Interpolate<Key>>(
    ...args: keyof Data extends never
      ? [key: Key]
      : [key: Key, data: Interpolate<Key>]
  ) => string

  /**
   * The primary means of translating text.
   */
  translate: <Key extends TranslationKey, Data = Interpolate<Key>>(
    ...args: keyof Data extends never
      ? [key: Key]
      : [key: Key, data: Interpolate<Key>]
  ) => React.ReactElement

  /**
   * Extends translate to allow for a fallback key to be used if the primary key
   * is invalid so that untyped strings can be tested for translation without
   * excessive type-casting.
   */
  translateWithFallback: <Key extends TranslationKey, Data = Interpolate<Key>>(
    ...args: keyof Data extends never
      ? [key: unknown, fallbackKey: Key]
      : [key: unknown, fallbackKey: Key, data: Interpolate<Key>]
  ) => React.ReactElement
}

export default function useTranslate(): UseTranslateReturn {
  const interpolateData = useCallback(
    (key: string, data?: Record<string, string | number>) =>
      intl.formatMessage({ id: key }, data).trim().replace(/\n/g, ' '),
    [],
  )

  const translateToString: UseTranslateReturn['translateToString'] =
    useCallback(interpolateData, [interpolateData])

  const translate: UseTranslateReturn['translate'] = useCallback(
    (...[key, data]) => {
      const text = interpolateData(key, data)
      return <>{renderElements(text, data)}</>
    },
    [interpolateData],
  )

  const translateWithFallback: UseTranslateReturn['translateWithFallback'] =
    useCallback(
      (key, fallbackKey, data?: Record<string, string | number>) => {
        if (typeof key === 'string' && key in intl.messages) {
          const text = interpolateData(key, data)
          return <>{renderElements(text, data)}</>
        }

        const text = interpolateData(fallbackKey, data)
        return <>{renderElements(text, data)}</>
      },
      [interpolateData],
    )

  return { translate, translateToString, translateWithFallback }
}

type UseFormatDateReturn = (date: Date | string | number) => string

export function useFormatDate(
  formatDateOptions: FormatDateOptions = SHORT_MONTH_DATE_FORMAT,
): UseFormatDateReturn {
  return useCallback(
    function formatDate(date) {
      return intl.formatDate(date, formatDateOptions)
    },
    [formatDateOptions],
  )
}

export function useFormatTime(
  formatTimeOptions: FormatDateOptions = SHORT_TIME_FORMAT,
): UseFormatDateReturn {
  return useCallback(
    function formatTime(time) {
      return intl.formatTime(time, formatTimeOptions)
    },
    [formatTimeOptions],
  )
}

interface DatePartsMap {
  year: string
  month: string
  day: string
  hour: string
  minute: string
  period: string
}

export function useDateParts(date: Date | string | number): DatePartsMap {
  return useMemo(() => {
    const parts = intl.formatDateToParts(date, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    const { value: year } = parts.find((part) => part.type === 'year') || {}
    const { value: month } = parts.find((part) => part.type === 'month') || {}
    const { value: day } = parts.find((part) => part.type === 'day') || {}
    const { value: hour } = parts.find((part) => part.type === 'hour') || {}
    const { value: minute } = parts.find((part) => part.type === 'minute') || {}
    const { value: period } =
      parts.find((part) => part.type === 'dayPeriod') || {}

    return {
      year: year ?? '',
      month: month ?? '',
      day: day ?? '',
      hour: hour ?? '',
      minute: minute ?? '',
      period: period ?? '',
    }
  }, [date])
}

type UseFormatCurrencyReturn = (
  amount: number,
  options?: {
    maximumFractionDigits?: number
  },
) => string

export function useFormatCurrency(): UseFormatCurrencyReturn {
  return useCallback(function formatCurrency(amount, options) {
    return intl.formatNumber(amount, {
      ...options,
      style: 'currency',
      currency: 'USD',
    })
  }, [])
}

type UseFormatNumberOrdinalReturn = (amount: number) => string

export function useFormatNumberOrdinal(): UseFormatNumberOrdinalReturn {
  return useCallback(function formatNumberOrdinal(amount) {
    const suffixes = new Map([
      ['one', 'st'],
      ['two', 'nd'],
      ['few', 'rd'],
      ['other', 'th'],
    ])
    const rule = intl.formatPlural(amount, { type: 'ordinal' })
    const suffix = suffixes.get(rule)
    return `${amount}${suffix}`
  }, [])
}

export function useFormatDuration(): (durationObject: Duration) => string {
  const { translateToString } = useTranslate()

  return useCallback(
    (durationObject: Duration) => {
      const { duration, unit } = durationObject

      switch (unit) {
        case 'minutes':
        case 'minute':
          return translateToString('duration_minute', { duration })
        case 'hours':
        case 'hour':
          return translateToString('duration_hour', { duration })
        case 'months':
        case 'month':
          return translateToString('duration_month', { duration })
        case 'weeks':
        case 'week':
          return translateToString('duration_week', { duration })
        case 'days':
        case 'day':
        default:
          return translateToString('duration_day', { duration })
      }
    },
    [translateToString],
  )
}
