import { useEffect, useMemo } from 'react';
import { DEVICE_GROUP_SUBTYPE, DEVICE_GROUP_STATUS, DEVICE_GROUP_TYPE, LOCATION_TYPE } from 'src/constants/device';
import useApi from 'src/hooks/useApi';
export default function useDeviceGroups() { const { data: deviceGroups, fetch: fetchDeviceGroups, loading } = useApi({ serviceName: 'vault', path: 'deviceGroups' }); useEffect(() => { void fetchDeviceGroups(); }, [fetchDeviceGroups]); return useMemo(() => loading ? 'loading' : deviceGroups == null ? null : deviceGroups.map(deviceGroup => ({ status: getStatus(deviceGroup), subtype: getGroupSubtype(deviceGroup), data: deviceGroup })), [loading, deviceGroups]); }
function getStatus(deviceGroup) {
    var _deviceGroup$devices$, _deviceGroup$devices;
    const devicesCount = (_deviceGroup$devices$ = (_deviceGroup$devices = deviceGroup.devices) === null || _deviceGroup$devices === void 0 ? void 0 : _deviceGroup$devices.length) !== null && _deviceGroup$devices$ !== void 0 ? _deviceGroup$devices$ : 0;
    if (deviceGroup.devices == null || devicesCount === 0) {
        return DEVICE_GROUP_STATUS.INACTIVE;
    }
    const activeDevicesCount = deviceGroup.devices.reduce((accumulator, device) => accumulator + (device.active ? 1 : 0), 0);
    if (devicesCount !== activeDevicesCount) {
        return DEVICE_GROUP_STATUS.LOST;
    } // Subtract 1 from the number of expected devices since the Casa recovery key
    // isn't included in deviceGroups
    let expectedDevicesCount = deviceGroup.expectedLocationTypes.length;
    expectedDevicesCount = expectedDevicesCount > 1 ? expectedDevicesCount - 1 : expectedDevicesCount;
    if (expectedDevicesCount === devicesCount && expectedDevicesCount === activeDevicesCount) {
        return DEVICE_GROUP_STATUS.ACTIVE;
    }
    return DEVICE_GROUP_STATUS.INACTIVE;
} /**
 * Calculates the virtual subtype property of a device group, identical to
 * iOS logic in /ios/CasaNetworking/Sources/CasaNetworking/Models/DeviceGroup.swift
 */
export function getGroupSubtype(deviceGroup) { const isBasicGroup = deviceGroup.label === DEVICE_GROUP_TYPE.BASIC_MULTISIG || deviceGroup.label === DEVICE_GROUP_TYPE.ETH_BASIC_MULTISIG; const isShieldGroup = deviceGroup.label === DEVICE_GROUP_TYPE.KEY_SHIELD || deviceGroup.label === DEVICE_GROUP_TYPE.ETH_SHIELD; const hasMobileLocation = deviceGroup.expectedLocationTypes.includes(LOCATION_TYPE.MOBILE); const hasSixthLocation = deviceGroup.expectedLocationTypes.includes(LOCATION_TYPE.SIXTH); if (isBasicGroup) {
    return hasMobileLocation ? DEVICE_GROUP_SUBTYPE.BASIC_MOBILE : DEVICE_GROUP_SUBTYPE.BASIC_HARDWARE;
} if (isShieldGroup) {
    return hasSixthLocation ? DEVICE_GROUP_SUBTYPE.SHIELD_INHERITANCE : DEVICE_GROUP_SUBTYPE.SHIELD_STANDARD;
} return null; } /**
* Calculates the translation key for a device group, identical to
* iOS logic in /ios/CasaNetworking/Sources/CasaNetworking/Models/DeviceGroup.swift
*/
export function getDeviceGroupTranslationKey(deviceGroupLabel, deviceGroupSubtype) { const labelMap = { [DEVICE_GROUP_TYPE.KEY_SHIELD]: 'btc_vault_5_key', [DEVICE_GROUP_TYPE.ETH_SHIELD]: 'eth_vault_5_key', [DEVICE_GROUP_TYPE.BASIC_MULTISIG]: 'btc_vault_3_key', [DEVICE_GROUP_TYPE.ETH_BASIC_MULTISIG]: 'eth_vault_3_key', [DEVICE_GROUP_TYPE.PHONE]: 'pay_key', [DEVICE_GROUP_TYPE.SINGLE_KEY]: 'single_key' }; if (deviceGroupSubtype === DEVICE_GROUP_SUBTYPE.SHIELD_INHERITANCE) {
    labelMap[DEVICE_GROUP_TYPE.KEY_SHIELD] = 'btc_vault_6_key';
    labelMap[DEVICE_GROUP_TYPE.ETH_SHIELD] = 'eth_vault_6_key';
} return labelMap[deviceGroupLabel]; }
export function isVault(deviceGroupLabel) { return [DEVICE_GROUP_TYPE.KEY_SHIELD, DEVICE_GROUP_TYPE.ETH_SHIELD, DEVICE_GROUP_TYPE.BASIC_MULTISIG, DEVICE_GROUP_TYPE.ETH_BASIC_MULTISIG].includes(deviceGroupLabel); }
