import { useCallback, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { RESPONSE_STATUS } from 'src/constants/api';
import { sendServiceRequest } from 'src/utils/api';
import { getInitialRedirect } from 'src/utils/route';
export default function useApi(_ref) {
    let { serviceName, path, method, credentials, fetchMode } = _ref;
    const location = useLocation();
    const serviceNameRef = useRef(serviceName);
    const pathRef = useRef(path);
    const methodRef = useRef(method);
    const credentialsRef = useRef(credentials);
    const fetchModeRef = useRef(fetchMode);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const fetch = useCallback(async function () {
        let { headers, body, pathData, query, token } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        try {
            setError(null);
            setLoading(true);
            const response = await sendServiceRequest({ method: methodRef.current, serviceName: serviceNameRef.current, path: pathRef.current, pathData, headers, body, query, token, credentials: credentialsRef.current, fetchMode: fetchModeRef.current });
            if (response.result != null || response.result == null && response.status === RESPONSE_STATUS.NO_CONTENT) {
                setData(response.result);
                return { status: response.status, data: response.result, error: null };
            }
            if (response.error == null) {
                throw new Error('Unexpected response (without data and error)');
            } // If any endpoint returns "unauthorized", check if just this endpoint
            // was unauthorized or if the user session has expired.
            if (response.status === RESPONSE_STATUS.UNAUTHORIZED && pathRef.current !== 'users/me' && (await isLoggedOut())) { // If an initial redirect is required, reload the current route
                // to discard any state in-memory.
                if (getInitialRedirect(location, null) != null) {
                    window.location.reload();
                }
            }
            setError(response.error);
            return { status: response.status, data: null, error: response.error };
        }
        catch (error) {
            console.error('Unexpected error %o', error);
            setError({ message: 'Unknown error' });
            return { status: RESPONSE_STATUS.SERVER_ERROR, data: null, error: error };
        }
        finally {
            setLoading(false);
        }
    }, [location]);
    return { data, loading, error, fetch };
} // Keep a flag to check if we're checking if the user has logged out.
// We need this to prevent sending multiple simultaneous requests to check.
let isCheckingLoggedOut = false;
async function isLoggedOut() { if (isCheckingLoggedOut) {
    return false;
} isCheckingLoggedOut = true; const getMeResponse = await sendServiceRequest({ method: 'GET', serviceName: 'vault', path: 'users/me' }); isCheckingLoggedOut = false; const user = getMeResponse.result; return user == null; }
